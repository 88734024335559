








































































































































































import { Observer } from 'mobx-vue'
import { Component, Vue, Inject } from 'vue-property-decorator'
import { walletStore } from '@/stores/wallet-store'
import HeroStaked from './hero-staked.vue'
import { AirDropViewModel } from '../viewmodels/airdrop-viewmodel'

@Observer
@Component({
  components: {
    HeroStaked,
  },
})
export default class MyAirdropTreasury extends Vue {
  @Inject() vm!: AirDropViewModel
  wallet = walletStore
  chainId = process.env.VUE_APP_CHAIN_ID

  copyAddress() {
    navigator.clipboard.writeText(this.vm.txHash!)
  }
}
