import { walletStore } from '@/stores/wallet-store'
import Web3 from 'web3'
import { blockchainHandler } from '@/blockchainHandlers'

const web3 = blockchainHandler.getWeb3(process.env.VUE_APP_CHAIN_ID)!

async function sendRequest(fx, from) {
  return await new Promise((resolve, reject) => {
    fx.send({ from })
      .on('receipt', (result) => resolve(result))
      .on('error', (error) => reject(error))
  })
}

export interface LockingInfo {
  index: string
  lockTime: string
  tokenIDs: string[]
  username: string
}

export class EmpirelandLockPoolHandler {
  lockPoolContract: any
  start?: number
  end?: number
  lockInfo?: LockingInfo

  constructor() {
    this.lockPoolContract = new web3.eth.Contract(
      require('./abis/empirelandLockPool.abi.json'),
      process.env.VUE_APP_LOCK_POOL_CONTRACT
    )
  }

  async init() {
    const methods = this.lockPoolContract.methods
    const [start, end] = (await blockchainHandler.etherBatchRequest(web3 as Web3, [
      methods.start(),
      methods.end(),
    ])) as any
    this.start = start
    this.end = end
    if (walletStore.account) {
      const lockingInfo = await methods.getLockingInfo(walletStore.account).call()
      this.lockInfo = lockingInfo
      console.log('=========', start, end, lockingInfo)
    }
  }

  injectMetamask(web3: Web3) {
    if (web3) {
      this.lockPoolContract = new web3.eth.Contract(
        require('./abis/empirelandLockPool.abi.json'),
        process.env.VUE_APP_LOCK_POOL_CONTRACT
      )
    }
  }

  async getHeroNftIds() {
    const balance = await this.lockPoolContract.methods.balanceOf(walletStore.account).call()
    const heroNftIds = [] as number[]
    for (let i = 0; i < balance; i++) {
      const tokenId = await this.lockPoolContract.methods.tokenOfOwnerByIndex(walletStore.account, i).call()
      heroNftIds.push(tokenId)
    }
    return heroNftIds
  }

  async lockNfts(nftsID: number[], discordUserName: string) {
    const methods = this.lockPoolContract.methods
    const txn = await sendRequest(methods.lock(discordUserName, nftsID), walletStore.account)
    return txn
  }

  async claimNfts() {
    const methods = this.lockPoolContract.methods
    const txn = await sendRequest(methods.claim(), walletStore.account)
    return txn
  }
}
