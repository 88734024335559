





























import { RareLevelColors } from '@/constants'
import { HeroNftModel } from '@/models/hero-nft-model'
import { Observer } from 'mobx-vue'
import { Component, Vue, Prop } from 'vue-property-decorator'

@Observer
@Component({
  components: {},
})
export default class HeroStaked extends Vue {
  @Prop() character!: HeroNftModel
  get color() {
    return RareLevelColors.find((item) => item.name === this.character.rareLevel)?.color || 'black'
  }
}
