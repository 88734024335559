import { HeroNftModel } from '@/models/hero-nft-model'
import { action, computed, IReactionDisposer, observable, reaction } from 'mobx'
import { EmpirelandLockPoolHandler, LockingInfo } from '@/blockchainHandlers/empirelandLockPoolHandler'
import { asyncAction } from 'mobx-utils'
import { loadingController } from '@/components/global-loading/global-loading-controller'
import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { EmpirelandHeroNftHandler } from '@/blockchainHandlers/empirelandHeroNftHandler'
import { Subject } from 'rxjs'
import { walletStore } from '@/stores/wallet-store'
import { chunk } from 'lodash-es'
import { appProvider } from '@/app-providers'
import moment from 'moment'

export class AirDropViewModel {
  _disposers: IReactionDisposer[] = []
  private _unsubcrible = new Subject()

  @observable heroList: HeroNftModel[] = []
  @observable heroStakedList: HeroNftModel[] = []
  @observable selectedHeroIds: number[] = []
  @observable openStakeDialog = false
  @observable openWithdrawDialog = false
  @observable showConfirmStakeTab = false
  @observable discordUserNameInput = ''
  @observable loading = false
  @observable lockingInfomation?: LockingInfo = undefined
  @observable txHash = ''

  lockPoolHandler?: EmpirelandLockPoolHandler
  heroNftHandler?: EmpirelandHeroNftHandler

  constructor() {
    this.lockPoolHandler = new EmpirelandLockPoolHandler()
    this.heroNftHandler = new EmpirelandHeroNftHandler()
    this.loadData()
    this._disposers = [
      reaction(
        () => walletStore.account,
        () => {
          if (walletStore.isChainIdValid) {
            this.loadData()
            this.heroNftHandler?.injectMetamask(walletStore.web3!)
            this.lockPoolHandler?.injectMetamask(walletStore.web3!)
          }
        }
      ),
    ]
  }

  @action.bound setOpenStakeDialog(value: boolean) {
    this.openStakeDialog = value
    this.resetHeroSelected()
  }

  @action.bound setShowConfirmStakeTab(value: boolean) {
    this.showConfirmStakeTab = value
  }

  @action.bound setOpenWithdrawDialog(value: boolean) {
    this.openWithdrawDialog = value
  }

  @asyncAction *loadData() {
    try {
      yield this.lockPoolHandler?.init()
      this.lockingInfomation = this.lockPoolHandler?.lockInfo
      this.heroStakedList = yield this.getHerosStakedInfo()
      loadingController.increaseRequest()
      if (!walletStore.isMetamask || !walletStore.account) return
      this.heroList = yield this.heroNftHandler?.getHeroNftIds()
      if (this.heroList && this.heroList?.length > 0) {
        const groups = chunk(this.heroList, 100)
        const characters: HeroNftModel[] = []
        for (const nftIds of groups) {
          const nfts = yield appProvider.api.heroNft.find<HeroNftModel>({
            ID_in: nftIds,
            _limit: -1,
          })
          characters.push(...nfts)
        }
        this.heroList = characters.sort((a, b) => +a.ID! - +b.ID!) // asc
      }
    } catch (error: any) {
      snackController.error(error.message)
    } finally {
      loadingController.decreaseRequest()
    }
  }

  @action setSelectedHeroIds(id: number) {
    if (!this.selectedHeroIds.includes(id)) {
      this.selectedHeroIds = [...this.selectedHeroIds, id]
    } else {
      this.selectedHeroIds = this.selectedHeroIds.filter((item: number) => item !== id)
    }
  }

  @action resetHeroSelected() {
    this.selectedHeroIds = []
  }

  @asyncAction *approveHeroBox() {
    try {
      yield this.heroNftHandler?.approveContract()
    } catch (error: any) {
      snackController.error(error.message)
    }
  }

  @asyncAction *lockNfts() {
    try {
      this.loading = true
      yield this.approveHeroBox()
      const txn = yield this.lockPoolHandler?.lockNfts(this.selectedHeroIds, this.discordUserNameInput)
      if (txn) {
        snackController.success(`Lock hero(s) successfully`)
        this.txHash = txn.transactionHash
      }
      yield this.loadData()
      this.setOpenStakeDialog(false)
    } catch (error: any) {
      snackController.error(error.message)
    } finally {
      this.loading = false
    }
  }

  @asyncAction *claim() {
    try {
      this.loading = true
      const txn = yield this.lockPoolHandler?.claimNfts()
      console.log('============txn', txn)
      if (txn) {
        snackController.success('Claim successfully')
        console.log("========txn", txn)
        this.txHash = txn.transactionHash
      }
      yield this.loadData()
      this.setOpenWithdrawDialog(false)
    } catch (error: any) {
      snackController.error(error.message)
    } finally {
      this.loading = false
    }
  }
  @asyncAction *getHerosStakedInfo() {
    const heroStaked = this.lockingInfomation && this.lockingInfomation[3]
    if (heroStaked?.length) {
      const nfts = yield appProvider.api.heroNft.find<HeroNftModel>({
        ID_in: heroStaked,
        _limit: -1,
      })
      return nfts
    }
    return []
  }

  @computed get lockingDiscordUserName() {
    return this.lockingInfomation && this.lockingInfomation[2]
  }

  @computed get totalNFTStaked() {
    return this.lockingInfomation && this.lockingInfomation[3]?.length
  }

  @computed get herosSeleted() {
    const heroSelected = this.selectedHeroIds.map((heroId) => {
      return this.heroList.find((item) => Number(item.ID) == heroId)
    })
    return heroSelected
  }

  @computed get rarityPowerStaked() {
    let total = 0
    this.heroStakedList.forEach((hero) => (total = total + Number(hero.rarityScore)))
    return total
  }

  @computed get stakeEnable() {
    return (
      moment(this.lockPoolHandler?.start, 'X').isBefore(moment()) &&
      moment(this.lockPoolHandler?.end, 'X').isAfter(moment()) &&
      !this.heroStakedList?.length
    )
  }

  @computed get withdrawEnable() {
    return moment(this.lockPoolHandler?.end, 'X').isBefore(moment()) && !!this.heroStakedList?.length
  }

  @computed get showStakeOpenTime() {
    return moment(this.lockPoolHandler?.start, 'X').isAfter(moment())
  }

  @computed get stakeOpen() {
    return this.lockingInfomation && moment(this.lockPoolHandler?.start, 'X').format('DD/MM/YYYY, HH:mm')
  }

  @computed get isStaked() {
    return !!this.heroStakedList?.length
  }

  @computed get isWithdrawable() {
    return this.lockingInfomation && moment().isAfter(moment(this.lockingInfomation[1], 'X'))
  }

  @computed get stakedTime() {
    return this.lockingInfomation && moment(this.lockingInfomation[0], 'X').format('DD/MM/YYYY, HH:mm')
  }

  @computed get unlockTime() {
    return moment(this.lockPoolHandler?.end, 'X').format('DD/MM/YYYY, HH:mm')
  }

  @computed get claimed() {
    return (
      !!this.heroStakedList?.length &&
      this.lockingInfomation &&
      moment().isAfter(moment(this.lockingInfomation[1], 'X'))
    )
  }

  destroy() {
    this._unsubcrible.next()
    this._unsubcrible.complete()
    this._disposers.forEach((d) => d())
  }
}
