










































































































import { RareLevelColors } from '@/constants'
import { Observer } from 'mobx-vue'
import { Component, Vue, Inject } from 'vue-property-decorator'
import { AirDropViewModel } from '../viewmodels/airdrop-viewmodel'
import HeroSelect from './hero-select.vue'
import { HeroNftModel } from '@/models/hero-nft-model'

@Observer
@Component({
  components: {
    HeroSelect,
  },
})
export default class StakeDialog extends Vue {
  @Inject() vm!: AirDropViewModel

  color(hero: HeroNftModel) {
    return RareLevelColors.find((item: HeroNftModel) => item.name === hero.rareLevel)?.color || 'black'
  }
}
