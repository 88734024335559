






























import { Observer } from 'mobx-vue'
import { Component, Vue, Inject } from 'vue-property-decorator'
import { AirDropViewModel } from '../viewmodels/airdrop-viewmodel'

@Observer
@Component({
  components: {},
})
export default class WithdrawDialog extends Vue {
  @Inject() vm!: AirDropViewModel
}
