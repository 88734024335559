

























import { Observer } from 'mobx-vue'
import { Component, Vue, Prop, Inject } from 'vue-property-decorator'
import { RareLevelColors } from '@/constants'
import { HeroNftModel } from '@/models/hero-nft-model'
import { AirDropViewModel } from '../viewmodels/airdrop-viewmodel'

@Observer
@Component({
  components: {},
})
export default class HeroSelect extends Vue {
  @Inject() vm!: AirDropViewModel
  @Prop() hideCheckbox!: boolean
  @Prop() character!: HeroNftModel

  get color() {
    return RareLevelColors.find((item) => item.name === this.character.rareLevel)?.color || 'black'
  }
}
