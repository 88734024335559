
































import { Observer } from 'mobx-vue'
import { Component, Vue, Provide } from 'vue-property-decorator'
import LeaderBoard from '../components/leader-board.vue'
import MyAirdropTreasury from '../components/my-airdrop-treasury.vue'
import StakeDialog from '../components/stake-dialog.vue'
import WithdrawDialog from '../components/widthdraw-dialog.vue'
import { AirDropViewModel } from '../viewmodels/airdrop-viewmodel'

@Observer
@Component({
  components: {
    LeaderBoard,
    MyAirdropTreasury,
    StakeDialog,
    WithdrawDialog,
  },
})
export default class AirdropTreasury extends Vue {
  @Provide() vm = new AirDropViewModel()
  duration = 300
  offset = 0
  easing = 'easeInOutCubic'
  target?: HTMLElement | null

  mounted() {
    this.target = document.getElementById('stake')
  }

  options() {
    return {
      duration: this.duration,
      offset: this.offset,
      easing: this.easing,
    }
  }
}
